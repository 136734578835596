import  { React, useLayoutEffect } from 'react'
import '../css/Contact.css'
import Footer from '../component/Footer'
const Contact = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
    <>
{/* ---------------------------seo------------------------------------ */}
<title>Top Fertility Care in Goregaon | Enclave Healthcare</title>
<meta http-equiv="cache-control" content="no-cache" />
      <meta http-equiv="expires" content="0" />
      <meta http-equiv="pragma" content="no-cache" />
      <meta
        property="title"
        content="Top Fertility Care in Goregaon | Enclave Healthcare"
      />
      <meta
        property="og:title"
        content="Top Fertility Care in Goregaon | Enclave Healthcare"
      />
      <meta
        property="description"
        content="Enclave Healthcare is the Top Fertility care in Goregaon. Schedule a consultation today for personalized and compassionate care tailored to your unique needs.
        "
      />
      <meta
        property="og:description"
        content="Enclave Healthcare is the Top Fertility care in Goregaon. Schedule a consultation today for personalized and compassionate care tailored to your unique needs.
        "
      />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="http://enclavehealthcare.com/" />
      <meta property="article:tag" content="Fertility Hospital in Goregaon" />
      <meta property="article:tag" content="Best Fertility Hospital in Goregaon" />
      <meta property="article:tag" content="best ivf hospital in mumbai" />
      <meta property="article:tag" content="Fertility Care in Goregaon" />
      <meta property="article:tag"  content="Best IVF Center in Goregaon"
      />
      <meta property="article:tag" content="Best Fertility Doctor in Goregaon" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <link rel="canonical" href="http://enclavehealthcare.com/top-fertility-care-goregaon"/>

    <meta
    name="keywords"
    content="Fertility Hospital in Goregaon,
    Best Fertility Hospital in Goregaon,
    Fertility Care in Goregaon,
    Best IVF Center in Goregaon,
    Best Fertility Doctor in Goregaon,
    Top Fertility Care in Goregaon,
    Top IUI Center in Goregaon
    Best IVF Care in Goregaon,
    Gynecologist in Goregaon,
    Best Doctor for Fertility in Goregaon ,
    Best Pediatrician in Goregaon,
    Best Obstetricians and Gynecologists in Goregaon,
    IVF with Egg Freezing Hospitals in Goregaon"
  />
 
    <link rel="canonical" href="http://enclavehealthcare.com/top-fertility-care-goregaon"/>
    {/* --------------------------seo end-------------------------------- */}
    
      <section className="page-title-area breadcrumb__area parallaxie">
          <div className="container">
      <div className="row justify-content-center">
        <div className="col-xxl-9">
          <div className="page-title-wrapper text-center">
            <h1 className="page-title mb-25">Top Fertility Care in Goregaon </h1>
            <div className="breadcrumb-menu">
              <nav
                aria-label="Breadcrumbs"
                className="breadcrumb-trail breadcrumbs"
              >
                <ul>
                  <li className="trail-item trail-begin">
                    <a href="/">
                      <span>Home</span>
                    </a>
                  </li>
                  <li className="trail-item trail-end">
                    <span>Contact</span>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
        <section className="section-lg">
  <div className="container">
    <div className="row">
      {/*Address Single Start*/}
      <div
        className="col-xl-4 col-lg-4 wow fadeInUp"
        data-wow-delay="100ms"
        style={{
          visibility: "visible",
          animationDelay: "100ms",
          animationName: "fadeInUp"
        }}
      >
        <div className="address__single">
          <div className="address__icon">
            <i className="fa fa-map-marker" />
          </div>
          <div className="address__content">
            <h4 className="address__title">We are Here</h4>
            <div className="add__details">
              <p>
              Enclave Healthcare 
Nandan CHS 
Opp Royal Challenge restaurant
Yashodham Enclave 
Goregaon East
              </p>
            </div>
          </div>
        </div>
      </div>
      {/*Address Single End*/}
      {/*Address Single Start*/}
      <div
        className="col-xl-4 col-lg-4 wow fadeInUp"
        data-wow-delay="200ms"
        style={{
          visibility: "visible",
          animationDelay: "200ms",
          animationName: "fadeInUp"
        }}
      >
        <div className="address__single">
          <div className="address__icon">
            <i className="fa fa-envelope" />
          </div>
          <div className="address__content">
            <h4 className="address__title">Email Address</h4>
            <div className="add__details">
              <p>
                <a href="mailto:info@example.com">enclavehealthcare@yahoo.com</a>
                {/* <a href="mailto:info@example2.com">profertivf@gmail.com</a> */}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/*Address Single End*/}
      {/*Address Single Start*/}
      <div
        className="col-xl-4 col-lg-4 wow fadeInUp"
        data-wow-delay="300ms"
        style={{
          visibility: "visible",
          animationDelay: "300ms",
          animationName: "fadeInUp"
        }}
      >
        <div className="address__single">
          <div className="address__icon">
            <i className="fa fa-phone" />
          </div>
          <div className="address__content">
            <h4 className="address__title">Quick Contact</h4>
            <div className="add__details">
              <p>
                <a href="tel:+12025550112">9619140797</a>
                <a href="tel:+12025550134">9869270858</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/*Address Single End*/}
    </div>
  </div>
</section>



<section className="bg-color-light section-lg">
  <div className="container">
  <div className="ele-header-area">
        <div className="heading-subheading text-center reverse-heading-yes">
          <h4 className="subtitle">Get in touch</h4>
          <h2 className="element-title">Get in touch with us</h2>
          <p>
        
Welcome to the Contact Us page of top fertility care in Goregaon,your trusted partner on the path to parenthood. We understand that embarking on a fertility journey is a significant step, and our team is here to provide the support and information you need.
Whether you have questions about our fertility services, want to schedule an appointment, or require assistance, our dedicated team is just a message or call away. Feel free to reach out using the contact information provided below.
</p>

<h2 className="element-title">Compassionate Guidance</h2>
<p>
Top fertility care in Goregaon,Our contact team is composed of caring professionals who understand the sensitivity of fertility concerns. We are here to offer compassionate guidance, address your inquiries, and provide the information necessary to make informed decisions about your fertility care.
</p>
<h2 className="element-title">Appointment Scheduling</h2>
<p>If you're ready to take the next step in your journey to parenthood, our contact team can assist you in scheduling appointments with our experienced fertility specialists. We are committed to making the process as seamless and convenient as possible.
</p>
<h2 className="element-title">Confidentiality Assured</h2>
<p>Your privacy is of utmost importance to us. Rest assured that all communication is handled with the strictest confidentiality. We understand the personal nature of fertility discussions, and your trust in our discretion is our priority.
</p>
<h2 className="element-title">Visit Us</h2>
<p>If you prefer face-to-face communication, our contact page includes the address of our fertility hospital. We welcome you to visit us for top fertility care in Goregaon , meet our team, and explore our state-of-the-art facilities.

At top fertility care in Goregaon ,we value open communication and are eager to assist you in any way we can. Your journey to parenthood begins with a conversation, and we look forward to being a supportive part of your story. Feel free to use the contact information provided below, and take the first step towards realizing your dream of starting or expanding your family.



          </p>
        </div>
    <div className="row">
     
      </div>
    </div>
    <div className="row">
      <div className="col-lg-6">
        <form
          action="php/ajax_sendmail.php"
          name="contactus"
          method="post"
          id="contact-form"
        >
          <div className="row mb-3 g-3">
            <div className="col-lg-6 col-md-6">
              <div className="form-group appoint__item name_field">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                />
                <span className="error_msg" id="name_error" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group appoint__item email_field">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                />
                <span className="error_msg" id="email_error" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group appoint__item phn_field">
                <input
                  type="tel"
                  name="phone"
                  className="form-control"
                  pattern="[0-9\-]*"
                  placeholder="Phone Number"
                />
                <span className="error_msg" id="phone_error" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
                        <div className="form-group appoint__item email_field">
                          <input
                            type="number"
                            name="age"
                            className="form-control custAge"
                            placeholder="Age"
                          />
                          <span className="error_msg" id="email_error" />
                        </div>
                      </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group appoint__item">
               
                {/* <div className="nice-select" tabIndex={0}> */}
                  <select className="nice-select">   Select Doctors
                  
                         
                            <option value="cardiology">Dr. Sudhir Naik(Senior Consultant Obstetrician and Gynaecologist)</option>
                            <option value="neurology">Dr. Savita Naik(Senior Pediatrician and Neonatologist)</option>
                            
                          </select>
                {/* </div> */}
                <span className="error_msg" id="subject_error" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group appoint__item date_field">
                <input
                  type="text"
                  name="date"
                  data-toggle="datetimepicker2"
                  className="form-control"
                  placeholder="Select Date"
                />
                <span className="error_msg" id="date_error" />
              </div>
            </div>
            {/* <div className="col-lg-6 col-md-6">
              <div className="form-group appoint__item time_field">
                <input
                  type="text"
                  id="time"
                  name="time"
                  className="form-control ui-timepicker-input"
                  placeholder="Select Time"
                  autoComplete="off"
                />
                <span className="error_msg" id="time_error" />
              </div>
            </div> */}
            <div className="col-lg-12 col-sm-12">
              <div className="form-group appoint__item">
                <textarea
                  name="message"
                  className="form-control"
                  rows={4}
                  placeholder="Message"
                  defaultValue={""}
                />
                <span className="error_msg" id="message_error" />
              </div>
            </div>
            <div className="col-lg-12 col-sm-12">
              <div className="form-group mailsendbtn mb-0 w-100">
                <button
                  type="submit"
                  name="contactus"
                  className="buttn buttn__primary buttn__rounded buttn__block"
                >
                  Submit
                </button>
                <div className="loading">
                  <img
                    className="img-fluid"
                    src="assets/images/ajax-loader.gif"
                    alt="top-fertility-care-goregaon"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="response-msg" />
      </div>
      <div className="col-lg-6">
        <div className="map">
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                className="gmap_iframe"
                width="100%"
                frameBorder={0}
                scrolling="no"
                marginHeight={0}
                marginWidth={0}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60303.109482985645!2d72.80727492707514!3d19.153913432956905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b71013c3c4f1%3A0x753bcc409a27ad22!2sEnclave%20Healthcare.%20Fertility.%20IVF!5e0!3m2!1sen!2sin!4v1671102147964!5m2!1sen!2sin"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<Footer/>
    </>
  )
}

export default Contact