import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./component/Navbar";
import Home from "./component/Home";
import About from "./component/About";
import Treatment from "./component/Treatment";
import TreatmentData from "./component/TreatmentData";
import Faqs from "./component/Faqs";
import Doctors from "./component/Doctors";
// import Franchise from './component/Franchise';
import Contact from "./component/Contact";
import Errorpage from "./component/Errorpage";
const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/best-fertility-hospital-goregaon" element={<About />} />
        {TreatmentData.map((route, index) => (
          <Route
            key={index}
            exact
            path={route.Slugs}
            element={<Treatment/>}
          />
        ))}
        <Route path="/best-ivf-center-goregaon" element={<Faqs />} />
        <Route
          path="/best-fertility-doctor-goregaon"
          element={<Doctors />}
        />
        <Route path="/top-fertility-care-goregaon" element={<Contact />} />
        <Route path='/*' element={<Errorpage/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
