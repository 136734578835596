import { React, useLayoutEffect,useState } from 'react'
import '../css/Service.css'
// import ReactDOM from 'react-dom';
import Footer from '../component/Footer'
import { useLocation } from "react-router-dom";
import TreatmentData from './TreatmentData'; 
// import ivf from "../images/ivf.jpg";
// import iui from "../images/iui-treatment.jpg";
import icsi from "../images/icsi.jpg";
import egg from "../images/egg.jpg";
import spr from "../images/sperm-ret.jpg";
import freez from "../images/freezing.jpg";
import surro from "../images/surrogacy.jpg";
import basic from "../images/basic.jpg";
import consult from "../images/consult.jpg";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


const Treatment = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  const search = useLocation();
  const Path = search.pathname;
  const [serviceData, setServiceData] = useState(TreatmentData);

  const TData = serviceData.find((e) => e.Slugs == Path);

  console.log(TData.ServiceName);
  return (
    <div>

      
       <section className="page-title-area breadcrumb__area parallaxie">
          <div className="container">
      <div className="row justify-content-center">
        <div className="col-xxl-9">
          <div className="page-title-wrapper text-center">
            <h1 className="page-title mb-25">{TData.Title}</h1>
            <div className="breadcrumb-menu">
              <nav
                aria-label="Breadcrumbs"
                className="breadcrumb-trail breadcrumbs"
              >
                <ul>
                  <li className="trail-item trail-begin">
                    <a href="/">
                      <span>Home</span>
                    </a>
                  </li>
                  <li className="trail-item trail-end">
                    <span>Treatments</span>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
         <div className="App">
      {/* <h1>Sample</h1> */}
      
      {/* <Tabs> */}
<div className="row">


{/* <div className="col-12 col-md-12">
<TabList>

<Tab>
    <p>Intra Uterine Insemination(IUI)</p>
  </Tab>
  <Tab>
    <p>In-Vitro Fertilization(IVF)</p>
  </Tab>
  <Tab>
    <p>Intra Cytoplasmic Sperm Injection(ICSI)</p>
  </Tab>
  <Tab>
    <p>Laproscopy</p>
  </Tab>
 
 
  <Tab>
    <p>Assisted Hatching</p>
  </Tab>
  <Tab>
    <p>Pediatric</p>
  </Tab>
  <Tab>
    <p>Obsterics & Gynaecology</p>
  </Tab>
  <Tab>
    <p>Egg & Embryo Freezing</p>
  </Tab>
</TabList>
</div> */}
<div className="col-lg-12">
{/* <TabPanel> */}
          <div className="panel-content">
            <div class="row">
             
              <div class=" col-12">
              <div class="servDesc">
              <h2>{TData.ServiceName}</h2>
              <p>{TData.ServicePara1}</p>
           
              </div>
              {/* <div class="serImg">
              <img src={basic}></img>
              </div> */}
                </div>
                <div class="col-lg-4  col-12">
              {/* <div class="serImg">
              <img src={basic} alt="Intrauterine insemination (IUI)"></img>
              </div> */}
              </div>
            </div>
        
          </div>
      
       

      
      {/* </Tabs> */}
    </div>

<Footer/>
    </div>
    </div>
    </div>
  )
}

export default Treatment