import React from 'react'
import '../css/Footer.css'
import Service from "./Service";
import comlogo from "../images/logo-removebg-preview-removebg-preview.png";
import {Link} from 'react-router-dom'
import TreatmentData from './TreatmentData';
const Footer = () => {
  return (
    <>
        <footer className="footer footer__area">
  <div className="footer-widget-area">
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-md-3 col-lg-3">
          <div className="footer__widget footer__about">
         <Link to="/">   <div className="footer__logo">
              <img src={comlogo} alt="" />
            </div></Link>
            <p>
            The centre’s focus has been Women and Child health. It offers Fertility/ IVF services along with Obstetrics and Gynaecology, adolescent and menopausal care services to women. 
            </p>
            <div className="social__icon">
              <div className="social__links">
                <ul className="social__icons d-flex">
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=100086838391528">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/enclavehealthcare/">
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="https://maps.app.goo.gl/LAENMR7mRFccMAZFA">
                      <i className="fa fa-google" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="footer__widget">
            <h3 className="widget__title mb-20">Services</h3>
            {TreatmentData.map((elem) => {
              const { id,ServiceName,Slugs} = elem;
              return (
                <>

<ul className="footer__link">
              <li>
              <Link to={`${Slugs}`}>{ServiceName}</Link>
                  {/* <i className="icon-arrow-right-2" /> */}
                 
                
              </li>
              </ul>

                </>
              );
            })}
           
          </div>
        </div>
        <div className="col-md-6 col-lg-2">
          <div className="footer__widget">
            <h3 className="widget__title mb-20">Useful Links</h3>
            <ul className="footer__link">
              <li>
                <Link to="/">
                  <i className="icon-arrow-right-2" />
                  Home
                </Link>
              </li>
              <li>
              <Link to="/best-fertility-hospital-goregaon">
                  <i className="icon-arrow-right-2" />
                  About us
                </Link>
              </li>
            
              <li>
              <Link to="/best-ivf-center-goregaon">
            
                  <i className="icon-arrow-right-2"/>  Faq
                </Link>
              </li>
              
              <li>
              <Link to="/best-fertility-doctor-goregaon">
                  <i className="icon-arrow-right-2"/>
                  Doctors
                </Link>
              </li>
              <li>
              <Link to="/top-fertility-care-goregaon">
                  <i className="icon-arrow-right-2" />
              Contact us
                </Link>
              </li>
          
             
            </ul>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="footer__widget">
            <h3 className="widget__title mb-20">Quick Contacts</h3>
            <div className="footer__contacts">
              <div className="phone mb-20">
                <h4 className="footer__info-title">Phone Number</h4>
                <div className="footer__info">
                  <i className="fa fa-phone" />
                  <a href="tel:+919619140797">9619140797, 8692031167</a>
                </div>
              </div>
              <div className="email mb-20">
                <h4 className="footer__info-title">Email Address</h4>
                <div className="footer__info">
                  <i className="fa fa-envelope" />
                  <a href="mailto:enclavehealthcare@gmail.com">enclavehealthcare@gmail.com</a>
                </div>
              </div>
              <div className="address mb-20">
                <h4 className="footer__info-title">Our Location</h4>
                <div className="footer__info">
                  <i className="fa fa-map-marker" />
                  <a href="https://www.google.com/maps/place/Enclave+Healthcare.+Fertility.+IVF/@19.1743735,72.8613181,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7b71013c3c4f1:0x753bcc409a27ad22!8m2!3d19.1743735!4d72.8635068?hl=en-GB&authuser=0">
                  Enclave Healthcare 
Nandan CHS 
Opp Royal Challenge restaurant
Yashodham Enclave 
Goregaon East
                  
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="footer-text-area">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center"><p>
        Copyright © 2022 Enclave  Healthcare All Rights Reserved.
        </p>
          <p>Developed by <a href="https://skdm.in/"> Shree Krishna Digital Marketing </a></p>
        </div>
      </div>
    </div>
  </div>
</footer>

        </>
  )
}

export default Footer
