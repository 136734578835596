import React from 'react'
import {Link} from 'react-router-dom'
import '../css/Navbar.css'
import comlogo from "../images/logo-removebg-preview-removebg-preview.png";
import $ from 'jquery'
import { Nav, Navbar, Container } from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Service from "../component/Service";
import TreatmentData from './TreatmentData';
const Navbar1 = () => {
//====Main menu===

  const handleClick = (e) => {
    if ($(e.target).closest("#checkbox").length === 0) {
      $("#checkbox").prop("checked", false);
    }
    else {
      $("#checkbox").prop("checked", true);
    }
  }
  return (
    <>

    
<section className="top-bar-area d-lg-block d-none">
  <div className="container">
    <div className="row">
      <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
        <div className="top-left">
          <ul className="top-contact-info">
            <li>
              <i className="fa fa-phone" style={{ color: "#fff" }} />
              
              <a href="tel:+919619140797" style={{ color: "#fff" }}>
                +91 9619140797
              </a>
            </li>
            <li>
              <i className="fa fa-envelope" />
              <a
                href="mailto: enclavehealthcare@yahoo.com"
                style={{ color: "#fff" }}
              >
                enclavehealthcare@gmail.com
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
        <div className="top-right clearfix">
          <ul className="social-links">
            <li>
              <a
                href="https://www.facebook.com/profile.php?id=100086838391528"
                target="_blank"
              >
                <i className="fa fa-facebook"/>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/enclavehealthcare/" target="_blank">
                <i className="fa fa-instagram" />
              </a>
            </li>
            <li>
              <a href="https://maps.app.goo.gl/LAENMR7mRFccMAZFA" target="_blank">
                <i className="fa fa-google-plus" />
              </a>
            </li>
            {/* <li>
              <a href="" target="_blank">
                <i className="fa fa-linkedin" />
              </a>
            </li> */}
          </ul>
        
        </div>
      </div>
    </div>
  </div>
</section>



<nav className="navbar ">
  <div className="navbar-container container">
    <input type="checkbox" name="" id="checkbox" />
    <div className="hamburger-lines" id="">
      <span className="line line1" />
      <span className="line line2" />
      <span className="line line3" />
    </div>
    <ul className="menu-items">
      <li className="" onClick={handleClick}>
       <Link to="/" >Home</Link>
      </li>
      <li className="" onClick={handleClick}>

  
      <Link to="/best-fertility-hospital-goregaon">About</Link>
      </li>
      <li>
      <NavDropdown title="Treatments" id="basic-nav-dropdown">
                          { TreatmentData.map((elem) => {
                            const { ServiceName,Slugs } = elem;
                            return (
                              <>
                                <NavDropdown.Item>
                                  <Link to={`${Slugs}`}>
                                    {ServiceName}
                                  </Link>
                                </NavDropdown.Item>
                              </>
                            );
                          })}
                        </NavDropdown>
     
      </li>
      <li>
        <Link to="/best-ivf-center-goregaon" onClick={handleClick}>Faqs</Link>
      </li>
      <li>
        <Link to="/best-fertility-doctor-goregaon" onClick={handleClick}>Doctors</Link>
      </li>
      {/* <li>
      <Link to="Franchise">Franchise</Link>
      </li> */}
      <li>
      <Link to="/top-fertility-care-goregaon" onclick={handleClick}>Contact</Link>
      </li>
    </ul>
    <Link to="/">
    <div className="logo"><img src={comlogo}/></div></Link>
  </div>
</nav>







</>
  )
}

export default Navbar1