import { React, useLayoutEffect } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import '../css/Faqs.css'
import Footer from '../component/Footer'
const Faqs = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
    
    <>
   <title>Best IVF Center in Goregaon | Enclave Healthcare</title>
<meta http-equiv="cache-control" content="no-cache" />
      <meta http-equiv="expires" content="0" />
      <meta http-equiv="pragma" content="no-cache" />
      <meta
        property="title"
        content="Best IVF Center in Goregaon | Enclave Healthcare"
      />
      <meta
        property="og:title"
        content="Best IVF Center in Goregaon | Enclave Healthcare"
      />
      <meta
        property="description"
        content="Discover unparalleled reproductive excellence at the best IVF center in Goregaon. Schedule a consultation today!! "
      />
      <meta
        property="og:description"
        content="Discover unparalleled reproductive excellence at the best IVF center in Goregaon. Schedule a consultation today!! "
      />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="http://enclavehealthcare.com/" />
      <meta property="article:tag" content="Fertility Hospital in Goregaon" />
      <meta property="article:tag" content="Best Fertility Hospital in Goregaon" />
      <meta property="article:tag" content="best ivf hospital in mumbai" />
      <meta property="article:tag" content="Fertility Care in Goregaon" />
      <meta property="article:tag"  content="Best IVF Center in Goregaon"
      />
      <meta property="article:tag" content="Best Fertility Doctor in Goregaon" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
   
    <meta
    name="keywords"
    content="Fertility Hospital in Goregaon,
    Best Fertility Hospital in Goregaon,
    Fertility Care in Goregaon,
    Best IVF Center in Goregaon,
    Best Fertility Doctor in Goregaon,
    Top Fertility Care in Goregaon,
    Top IUI Center in Goregaon
    Best IVF Care in Goregaon,
    Gynecologist in Goregaon,
    Best Doctor for Fertility in Goregaon ,
    Best Pediatrician in Goregaon,
    Best Obstetricians and Gynecologists in Goregaon,
    IVF with Egg Freezing Hospitals in Goregaon"
  />
 
    <link rel="canonical" href="http://enclavehealthcare.com/best-ivf-center-goregaon"/>
    {/* --------------------------seo end-------------------------------- */}
          <section className="page-title-area breadcrumb__area parallaxie">
          <div className="container">
      <div className="row justify-content-center">
        <div className="col-xxl-9">
          <div className="page-title-wrapper text-center">
            <h1 className="page-title mb-25">Best IVF Center in Goregaon</h1>
            <div className="breadcrumb-menu">
              <nav
                aria-label="Breadcrumbs"
                className="breadcrumb-trail breadcrumbs"
              >
                <ul>
                  <li className="trail-item trail-begin">
                    <a href="/">
                      <span>Home</span>
                    </a>
                  </li>
                  <li className="trail-item trail-end">
                    <span>Faq's</span>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
    <section>
        <div className="container">
        <div className="ele-header-area">
            <div className="heading-subheading text-center reverse-heading-yes">
              <h4 className="subtitle">Get Answers</h4>
              <h2 className="title">Frequently Asked Question</h2>
              <p>Welcome to the FAQ page of the best IVF centre in Goregaon, your trusted destination for fertility care. Here, we address common questions to provide you with comprehensive information about our services and support your journey towards parenthood.
</p>
            </div>
          </div>
            <div className="row">
            <div className="col-md-6 col-12"> 
            <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header >What is infertility?</Accordion.Header>
        <Accordion.Body>
        Not getting pregnant even after 1 year of planned unprotected intercourse.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Is infertility a common problem?</Accordion.Header>
        <Accordion.Body>
        It affects 2-5% of the population and the numbers are increasing every year.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Is infertility just a woman's problem?</Accordion.Header>
        <Accordion.Body>
        Is infertility just a woman's problem?
No. Infertility can be because of male and female factors or both. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>What causes infertility in men?</Accordion.Header>
        <Accordion.Body>
        Common causes of male infertility are Varicocele. Ejaculation disorders, undescended testis, disorders or blockages of spermatic tubes, hormonal imbalances etc.

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>What increases a man's risk of infertility?</Accordion.Header>
        <Accordion.Body>
        Smoking, use of alcohol or illicit drugs, infections, trauma to testis, certain infections, being overweight are the risk factors for infertility in male
        </Accordion.Body>
      </Accordion.Item>
    </Accordion></div>
    <div className="col-md-6 col-12"> <Accordion>
      <Accordion.Item eventKey="5">
        <Accordion.Header >What causes infertility in women?</Accordion.Header>
        <Accordion.Body>
        Ovulation disorders, PCOD, Tubal infections, endometriosis, malformations of the uterus etc are the common causes of infertility in female. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>What things increase a woman's risk of infertility?</Accordion.Header>
        <Accordion.Body>
        Increasing age, alcohol and tobacco use, use of illicit drugs and pelvic infections are the risk factors for female infertility 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="9">
        <Accordion.Header>How does age affect a woman's ability to have children?</Accordion.Header>
        <Accordion.Body>
        With increasing age the both the quality and quantity of eggs in the woman reduces
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8">
        <Accordion.Header>What are the different types of assisted reproductive technology (ART)?</Accordion.Header>
        <Accordion.Body>
        Intrauterine insemination (IUI)
In Vitro Fertilisation (IVF) with Embryo transfer 
Intracytoplasmic sperm injection (ICSI) 
Egg freezing 
Sperm freezing
Embryo freezing 
Testicular aspiration of sperm 
Percutaneous epididymal sperm aspiration 
Etc
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8">
        <Accordion.Header> How long should women try to get pregnant before calling their doctors??</Accordion.Header>
        <Accordion.Body>
        Women should consult the doctor if she fails to become pregnant after 1 year of unprotected intercourse 
        </Accordion.Body>
      </Accordion.Item>
    </Accordion></div>
    </div>
            </div>
        
    </section>
  
         <Footer/>
    </>
  );
}

export default Faqs