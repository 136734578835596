import  { React, useLayoutEffect } from "react";
import Footer from "../component/Footer";
import "../css/Doctors.css";
import sudhir from '../images/clinic/IMG_2249.JPG';
import savita from '../images/clinic/IMG_2260.JPG';
const Doctors = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
    <div>

{/* ---------------------------seo------------------------------------ */}
<title>Best Fertility Doctor in Goregaon | Enclave Healthcare </title>
<meta http-equiv="cache-control" content="no-cache" />
      <meta http-equiv="expires" content="0" />
      <meta http-equiv="pragma" content="no-cache" />
      <meta
        property="title"
        content="Best Fertility Doctor in Goregaon | Enclave Healthcare"
      />
      <meta
        property="og:title"
        content="Best Fertility Doctor in Goregaon | Enclave Healthcare"
      />
      <meta
        property="description"
        content="Choose the Best Fertility Doctor in Goregaon for expert guidance on your parenthood journey. We offer personalized solutions for your unique needs.
        "
      />
      <meta
        property="og:description"
        content="Choose the Best Fertility Doctor in Goregaon for expert guidance on your parenthood journey. We offer personalized solutions for your unique needs.
        "
      />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="http://enclavehealthcare.com/" />
      <meta property="article:tag" content="Fertility Hospital in Goregaon" />
      <meta property="article:tag" content="Best Fertility Hospital in Goregaon" />
      <meta property="article:tag" content="best ivf hospital in mumbai" />
      <meta property="article:tag" content="Fertility Care in Goregaon" />
      <meta property="article:tag"  content="Best IVF Center in Goregaon"
      />
      <meta property="article:tag" content="Best Fertility Doctor in Goregaon" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
   
    <meta
    name="keywords"
    content="Fertility Hospital in Goregaon,
    Best Fertility Hospital in Goregaon,
    Fertility Care in Goregaon,
    Best IVF Center in Goregaon,
    Best Fertility Doctor in Goregaon,
    Top Fertility Care in Goregaon,
    Top IUI Center in Goregaon
    Best IVF Care in Goregaon,
    Gynecologist in Goregaon,
    Best Doctor for Fertility in Goregaon ,
    Best Pediatrician in Goregaon,
    Best Obstetricians and Gynecologists in Goregaon,
    IVF with Egg Freezing Hospitals in Goregaon"
  />
 
    <link rel="canonical" href="http://enclavehealthcare.com/best-fertility-doctor-goregaon"/>
    {/* --------------------------seo end-------------------------------- */}

     <section className="page-title-area breadcrumb__area parallaxie">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-9">
              <div className="page-title-wrapper text-center">
                <h1 className="page-title mb-25">Best Fertility Doctor in Goregaon </h1>
                <div className="breadcrumb-menu">
                  <nav
                    aria-label="Breadcrumbs"
                    className="breadcrumb-trail breadcrumbs"
                  >
                    <ul>
                      <li className="trail-item trail-begin">
                        <a href="/">
                          <span>Home</span>
                        </a>
                      </li>
                      <li className="trail-item trail-end">
                        <span>Doctors</span>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="ele-header-area">
            <div className="heading-subheading text-center reverse-heading-yes">
              <h1 className="subtitle">Meet our team</h1>
              <h2 className="title">Years  experience of treatment</h2>
              A team of best fertility doctors in Goregaon collaborates to provide exceptional healthcare tailored to your individual needs.At our hospital, we believe in a patient-centered approach that goes beyond treating symptoms. Our doctors focus on understanding your unique health goals and work collaboratively to develop personalized treatment plans that address your specific needs.
            </div>
          </div>

          <div className="row pt-lg-5 pb-lg-5">
            <div className="col-lg-3">
              <div className="docImg">
                <img src={sudhir} alt="best fertility doctor "/>
              </div>

              <div className="docName">
                <h2>Dr. Sudhir Naik</h2>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="docDetail">
                <div class="row rowspace">
                  {/* <div class="col-sm-4 ">
                        <div class="Specialty">
                            <span>Specialty</span>
                        </div>
                    </div> */}
                  <div class="col-12">
                    <div class="Specialty">
                      <b>Specialist: &nbsp;</b>
                      <span class="description">
                        IVF Consultant & Board Member
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row rowspace">
                  {/* <div class="col-sm-4 ">
                       
                    </div> */}
                  <div class="col-12">
                    <div class="Specialty">
                      <span>
                        <b>Degrees: &nbsp;</b>
                      </span>
                      <span class="description">MD, DGO, FCPS</span>
                    </div>
                  </div>
                </div>
                <div class="row rowspace">
                  {/* <div class="col-sm-4 ">
                        <div class="Specialty">
                            <span>Training</span>
                        </div>
                    </div> */}
                  <div class="col-12">
                    <div class="Specialty">
                      <span>
                        <b>Training: &nbsp;</b>
                      </span>
                      <span class="description">
                      He has an experience of 15 years as a fertility & IVF consultant and has successfully treated hundreds of childless couples.

He is Director and Consultant Obstetrician and Gynaecologist at Shree Guru Hospital and Oyster hosptial Goregaon.

He is Visiting Consultant at Cloud 9 and Surya Hospitals

He is Honorary visiting Gynaecologist at Balasaheb Thackeray Municipal Hospital
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row rowspace">
                  {/* <div class="col-sm-4 ">
                        <div class="Specialty">
                            <span>Achievements</span>
                        </div>
                    </div> */}
                  <div class="col-12">
                    <div class="Specialty">
                      <span>
                        <b>Description: &nbsp;</b>
                      </span>
                      <span class="description">
                      Dr. Sudhir G Naik is a senior Consultant Obstetrician and Gynaecologist with over 27 years of experience. 

He is recognized as a specialist in high-risk pregnancies and complicated vaginal deliveries.
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div class="row rowspace">
                    <div class="col-sm-4 ">
                        <div class="Specialty"><span>Available Days</span></div>
                    </div>
                    <div class="col-sm-8">
                        <div class="Specialty">
                            <span class="description">Monday to Saturday</span>
                        </div>
                    </div> 
                </div> */}
              </div>
            </div>
          </div>
          <div className="row pt-lg-5 pb-lg-5">
            <div className="col-lg-3">
              <div className="docImg">
                <img src={savita} alt="best-doctor-goregaon"></img>
              </div>

              <div className="docName">
                <h2>Dr. Savita Naik</h2>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="docDetail">
                <div class="row rowspace">
                  {/* <div class="col-sm-4 ">
                        <div class="Specialty">
                            <span>Specialty</span>
                        </div>
                    </div> */}
                  <div class="col-12">
                    <div class="Specialty">
                      <b>Role: &nbsp;</b>
                      <span class="description">
                      Senior Pediatrician and Neonatologist
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row rowspace">
                  {/* <div class="col-sm-4 ">
                       
                    </div> */}
                  <div class="col-12">
                    <div class="Specialty">
                      <span>
                        <b>Degrees: &nbsp;</b>
                      </span>
                      <span class="description">MBBS,MD, PG Diploma in Pediatric Nutrition </span>
                    </div>
                  </div>
                </div>
                <div class="row rowspace">
                  {/* <div class="col-sm-4 ">
                        <div class="Specialty">
                            <span>Training</span>
                        </div>
                    </div> */}
                  <div class="col-12">
                    <div class="Specialty">
                      <span>
                        <b>Description: &nbsp;</b>
                      </span>
                      <span class="description">
                      Dr. Savita Naik is a senior Pediatrician and Neonatologist with over 25 years of experience. She is recognised as a specialist in Pediatric Infectious Diseases and Neonatology. She is Director and Consultant Pediatrician at Shree Guru & Oyster hospitals Goregaon
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
         
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Doctors;
