import  { React, useLayoutEffect } from "react";
import clinic from "../images/clinic/IMG_2292.JPG";
import "../css/About.css";
import Footer from "./Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Autoplay } from "swiper";
import "swiper/css/navigation";
import test1 from '../images/testi.png';
const About = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
    <>
<title>Best Fertility Hospital in Goregaon | Enclave Healthcare </title>
<meta http-equiv="cache-control" content="no-cache" />
      <meta http-equiv="expires" content="0" />
      <meta http-equiv="pragma" content="no-cache" />
      <meta
        property="title"
        content="Best Fertility Hospital in Goregaon | Enclave Healthcare"
      />
      <meta
        property="og:title"
        content="Best Fertility Hospital in Goregaon | Enclave Healthcare"
      />
      <meta
        property="description"
        content="Enclave Healthcare is the best Fertility Hospital in Goregaon. Schedule a consultation today for personalized and effective fertility solutions.
        "
      />
      <meta
        property="og:description"
        content="Enclave Healthcare is the best Fertility Hospital in Goregaon. Schedule a consultation today for personalized and effective fertility solutions.
        "
      />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="http://enclavehealthcare.com/" />
      <meta property="article:tag" content="Fertility Hospital in Goregaon" />
      <meta property="article:tag" content="Best Fertility Hospital in Goregaon" />
      <meta property="article:tag" content="best ivf hospital in mumbai" />
      <meta property="article:tag" content="Fertility Care in Goregaon" />
      <meta property="article:tag"  content="Best IVF Center in Goregaon"
      />
      <meta property="article:tag" content="Best Fertility Doctor in Goregaon" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
   
    <meta
    name="keywords"
    content="Fertility Hospital in Goregaon,
    Best Fertility Hospital in Goregaon,
    Fertility Care in Goregaon,
    Best IVF Center in Goregaon,
    Best Fertility Doctor in Goregaon,
    Top Fertility Care in Goregaon,
    Top IUI Center in Goregaon
    Best IVF Care in Goregaon,
    Gynecologist in Goregaon,
    Best Doctor for Fertility in Goregaon ,
    Best Pediatrician in Goregaon,
    Best Obstetricians and Gynecologists in Goregaon,
    IVF with Egg Freezing Hospitals in Goregaon"
  />
 
    <link rel="canonical" href="http://enclavehealthcare.com/best-fertility-hospital-goregaon"/>
    {/* --------------------------seo end-------------------------------- */}
      <section className="page-title-area breadcrumb__area parallaxie">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-9">
              <div className="page-title-wrapper text-center">
                <h1 className="page-title mb-25">Best Fertility Hospital in Goregaon</h1>
                <div className="breadcrumb-menu">
                  <nav
                    aria-label="Breadcrumbs"
                    className="breadcrumb-trail breadcrumbs"
                  >
                    <ul>
                      <li className="trail-item trail-begin">
                        <a href="/">
                          <span>Home</span>
                        </a>
                      </li>
                      <li className="trail-item trail-end">
                        <span>About</span>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-section pt-lg-5 pt-2 pb-lg-5 pb-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 wow fadeInLeft mb-30">
              <div className="heading-subheading text-justify my-4">
                <h1 className="subtitle">About us</h1>
              <h2 className="title">Dr. Sudhir G Naik</h2>
              <div className="about-name">Senior Consultant Obstetrician and Gynaecologist </div>
                <p>
                Dr. Sudhir G Naik is a senior Consultant Obstetrician and Gynaecologist with over 27 years of experience. 

He is recognized as a specialist in high-risk pregnancies and complicated vaginal deliveries.</p>
<ul className="m-0 p-0">
  <li><p>He has an experience of 15 years as a fertility & IVF consultant and has successfully treated hundreds of childless couples. </p>
  </li>
  <li><p>He is Director and Consultant Obstetrician and Gynaecologist at Shree Guru Hospital and Oyster hosptial Goregaon.</p></li>
  <li><p>He is Visiting Consultant at Cloud 9 and Surya Hospitals</p></li>
  <li><p>He is Honorary visiting Gynaecologist at Balasaheb Thackeray Municipal Hospital </p></li>
  <li><p>He  had a brilliant academic career with MBBS from grant medical college, Sir JJ hospital Mumbai in 1991, and MD degree in 1994 from Seth G S Medical College Kem Hospital Mumbai. </p></li>
  <li><p>He is the winner of the Dr C G Saraiya and Sir Mangaldas Mehta Gold Medal for topping the MD exams of Bombay university in 1994. </p></li>
  <li><p>He is also a Fellow of the College  of physicians and surgeons,Mumbai</p></li>
  
  </ul>
                
                 <div className="mb-5">
                <div className="about-name-wrapper">
                  
                  {/* <div className="about-rol">
                    Senior Pediatrician and Neonatologist
                  </div> */}
                </div>
              </div>
              </div>
             
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-5 wow fadeInLeft mb-30">
            <div className="heading-subheading text-justify my-4">
                {/* <h4 className="subtitle">About us</h4> */}
              <h2 className="title">Dr. Savita S Naik</h2>
              <div className="about-name">Senior Pediatrician and Neonatologist</div>
                <p>
                Dr. Savita Naik is a senior Pediatrician  and Neonatologist  with over 25 years of experience. She is recognised as a specialist in Pediatric Infectious Diseases and Neonatology. 
She is Director and Consultant Pediatrician at Shree Guru & Oyster hospitals Goregaon </p>
<ul className="m-0 p-0">
  <li><p>She had a brilliant academic career with  distinctions in most  subjects during her MBBS education  from Topiwala National medical college, Nair Hospital  Mumbai in 1993, and her  MD degree in 1996 from Seth G S Medical College Kem Hospital Mumbai. </p></li>
  <li><p>She is the winner of the Dr Palekar Medal for topping the MD exams from Seth G S Medical College in 1996 . </p></li>
  <li><p>She was conferred the Diplomate of National Board New Delhi in 1994.</p></li>
  <li><p>She has also obtained PG Diploma in Pediatric Nutrition from Boston University! </p></li>
  <li><p>She has presented papers and been Faculty at National and State level conferences.</p></li>
  <li><p>She is an Ex Honorary Pediatrician at M W Desai Municipal hospital Malad </p>
</li>
<li>
<p>She is visiting Pediatrician and neonatologist at several nursing homes.</p>
</li>
</ul>

               
                 <div className="mb-5">
                <div className="about-name-wrapper">
                  
                  {/* <div className="about-rol">
                    Senior Pediatrician and Neonatologist
                  </div> */}
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="call-action parallaxie  pt-lg-5 pt-2 pb-lg-5 pb-2">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="heading-subheading heading__title text-center reverse-heading-yes">
              <h4 className="sub-title"> Enquiry</h4>
              <h2 className="element-title text-light">
                Follow our easy steps to get connect
              </h2>
              <div className="action__phone mt-lg-5 mt-2 mb-lg-5 mb-2">
                {/* <span>Call Now</span> */}
                <a
                  href="tel:919619140797"
                  className="buttn buttn__white buttn__outlined buttn__rounded"
                >
                  <i className="icon-call3 me-1" /> +919619140797
                </a>
              </div>
              {/* <div className="redBtn">
                <a>
                  <i className="fa fa-arrow-right"></i>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="our-features">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-7 col-12 text-sm-center text-lg-start wow fadeInLeft"
              data-wow-delay="200ms"
              style={{
                visibility: "visible",
                animationDelay: "200ms",
                animationName: "fadeInLeft",
              }}
            >
              <div className="heading-subheading text-align-left">
                <h1 className="subtitle">Our resposibility</h1>
                <h2 className="title">What We do for you</h2>
                <p>
                Welcome to the best fertility hospital in Goregaon, a leading fertility hospital committed to helping individuals and couples navigate the path to parenthood. At our state-of-the-art facility, we bring together a team of dedicated fertility specialists, reproductive endocrinologists, and support staff, all driven by a shared commitment to providing compassionate and comprehensive care. Recognizing the unique challenges that come with fertility concerns, we prioritize personalized treatment plans tailored to each individual's needs. In the best fertility hospital in Goregaon our approach combines cutting-edge medical technology with a holistic understanding of the emotional aspects of fertility struggles. We strive to create a patient-centric environment, fostering trust, open communication, and confidentiality. With a focus on transparency, our team ensures you are well-informed at every step of your fertility journey. Join us at the best fertility hospital in Goregaon where expertise, empathy, and innovation converge to turn your dreams of parenthood into reality.
                </p>
                <br/>
                      </div>
              {/* <div className="col-12 col-12">
                <div className="featured__img">
                  <i className="fa fa-money" />
                  <h3>Mission</h3>
                  <p>
                    We shall strive to provide comprehensive fertility services
                    for aspiring parents in a personalized and caring
                    environment. We shall strive to offer the most advanced
                    reproductive technologies available with the highest level
                    of patient privacy and ethical standards. We shall strive to
                    ensure the best possible success rate for our patients
                    through the collective expertise of our clinical team.
                  </p>
                </div>
              </div> */}
            </div>
            <div className="col-lg-5 col-12 wow fadeInRight">
              <div className="featured__img">
               <img src={clinic} alt="Best Fertility Hospital"/>
              </div>
            </div>
           
          </div>
         
        </div>
      </section>

      <section className="testimonial-section section-lg">
        <div className="container">
          <div className="ele-header-area">
            <div className="heading-subheading text-center reverse-heading-yes">
              <h4 className="subtitle">Testimonial</h4>
              <h2 className="element-title">Satisfied Patients Reviews</h2>
            </div>
          </div>

          <Swiper
           
            breakpoints={{
                
              570: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView:3,
            spaceBetween:30
              },
            }}
            navigation={true}
            modules={[Navigation]}
           
            className="testSwiper"
          >
            <SwiperSlide>
              {/* Slide1 */}
              <article className="testi-item__single">
                <div className="testi-item__client-box">
                  <div className="testi-item__client-img">
                    <img src={test1} alt="Best  Hospital in Goregaon" />
                  </div>
                  <div className="testi-item__client">
                    <h4 className="testi-item__client-name">Kajal Desai</h4>
                    {/* <p className="testi-item__client-sub-title">director</p> */}
                  </div>
                </div>
                <p className="testi-item__text">
                Dr Sudhir Naik delivered my baby safely when I was pregnant and Dr Savita Naik took care of my baby. 
I consult Dr Savita Naik for any Pediatric problems for my child. My baby received all her Pediatric vaccines from Dr Savita. Their new set up Enclave has a wonderful ambience and radiates positivity 
                </p>
                <div className="ratings">
                  <div className="testi-item__star">
                    <ul>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <span>(5.0)</span>
                      </li>
                    </ul>
                  </div>
                  <div className="testi-item__quote">
                    <i className="icon-quote" />
                  </div>
                </div>
              </article>
            </SwiperSlide>
            <SwiperSlide>
              {/* Slide2 */}
              <article className="testi-item__single">
                <div className="testi-item__client-box">
                  <div className="testi-item__client-img">
                    <img src={test1} alt=" Fertility Hospital in Goregaon" />
                  </div>
                  <div className="testi-item__client">
                    <h4 className="testi-item__client-name">Pushpa Kanwar</h4>
                    {/* <p className="testi-item__client-sub-title">director</p> */}
                  </div>
                </div>
                <p className="testi-item__text">
                I was unable to conceive even after 10 years of marriage. I was taking treatment from various doctors for last 8 years. I met Dr Sudhir Naik last December. He analysed my case and my reports and started treatment! I conceived and currently I am 6 months pregnant. 
I am grateful to Dr Sudhir Naik and I will strongly recommend him for patients who want Infertility IVF treatment-
                </p>
                <div className="ratings">
                  <div className="testi-item__star">
                    <ul>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <span>(5.0)</span>
                      </li>
                    </ul>
                  </div>
                  <div className="testi-item__quote">
                    <i className="icon-quote" />
                  </div>
                </div>
              </article>
            </SwiperSlide>
            <SwiperSlide>
              {/* Slide1 */}
              <article className="testi-item__single">
                <div className="testi-item__client-box">
                  <div className="testi-item__client-img">
                    <img src={test1} alt=" Hospital in Goregaon" />
                  </div>
                  <div className="testi-item__client">
                    <h4 className="testi-item__client-name">Prachi Palande</h4>
                    {/* <p className="testi-item__client-sub-title">director</p> */}
                  </div>
                </div>
                <p className="testi-item__text">
                I Visited Enclave Healthcare for My infertility The Doctor made me fuel Relaxed Explained the detailed investigation required and recommend best option for me made me feel More confident of achieving my pregnancy soon..
                </p>
                <div className="ratings">
                  <div className="testi-item__star">
                    <ul>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <span>(5.0)</span>
                      </li>
                    </ul>
                  </div>
                  <div className="testi-item__quote">
                    <i className="icon-quote" />
                  </div>
                </div>
              </article>
            </SwiperSlide>
            <SwiperSlide>
              {/* Slide1 */}
              <article className="testi-item__single">
                <div className="testi-item__client-box">
                <div className="testi-item__client-img">
                    <img src={test1} alt="Best  Hospital in Goregaon" />
                  </div>
                  <div className="testi-item__client">
                    <h4 className="testi-item__client-name">Mansi Ghanekar8</h4>
                    <p className="testi-item__client-sub-title">director</p>
                  </div>
                </div>
                <p className="testi-item__text">
                Well organized clinic Appointment process was smooth,staff was very friendly doctors were very knowledgeable.
                </p>
                <div className="ratings">
                  <div className="testi-item__star">
                    <ul>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <span>(5.0)</span>
                      </li>
                    </ul>
                  </div>
                  <div className="testi-item__quote">
                    <i className="icon-quote" />
                  </div>
                </div>
              </article>
            </SwiperSlide>
          </Swiper>
          {/* <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
        <span
          className="swiper-pagination-bullet swiper-pagination-bullet-active"
          tabIndex={0}
          role="button"
          aria-label="Go to slide 1"
          aria-current="true"
        />
        <span
          className="swiper-pagination-bullet"
          tabIndex={0}
          role="button"
          aria-label="Go to slide 2"
        />
      </div> */}
          <span
            className="swiper-notification"
            aria-live="assertive"
            aria-atomic="true"
          />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default About;
