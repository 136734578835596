import React from 'react'
import {Link} from "react-router-dom";
import Footer from "../component/Footer"
const Errorpage = () => {
  return (
    <div>
            <section className="page-title-area breadcrumb__area parallaxie">
          <div className="container">
      <div className="row justify-content-center">
        <div className="col-xxl-9">
          <div className="page-title-wrapper text-center">
            <h1 className="page-title mb-25">404-Page Not Found</h1>
            <div className="breadcrumb-menu">
              <nav
                aria-label="Breadcrumbs"
                className="breadcrumb-trail breadcrumbs"
              >
                <ul>
                  <li className="trail-item trail-begin">
                    <a href="/">
                      <span>Home</span>
                    </a>
                  </li>
                  <li className="trail-item trail-end">
                    <span>404</span>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
    <section>
        <div className="container">
        <div className="ele-header-area">
            <div className="heading-subheading text-center reverse-heading-yes">
           
              <h2 className="title">Page Not Found</h2>
              <p>Back to <Link to="/">Home</Link>
</p>
            </div>
          </div>
          
            </div>
        
    </section>
  
         <Footer/>
    </div>
  )
}

export default Errorpage