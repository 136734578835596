// import React from "react";
import fert from "../images/ivf.jpg";
import IV from "../images/ivf-process.png";
import mater from "../images/pregnant.jpg";
import pedia from "../images/pediatric.jpg";
// import gynaec from "../images/gynaec.jpg";
import dermat from "../images/dermatology.jpg";
// import fert from "../images/ivf.jpg";
// import iui from "../images/iui-treatment.jpg";
import icon1 from "../images/icons/ivf.png";
import icon_fert from "../images/icons/pregnant.png";
import icon_mater from "../images/icons/mother.png";
import icon_pedia from "../images/icons/pediatrics.png";
import icon_gynaec from "../images/icons/pelvic-exam.png";
import icon_dermot from "../images/icons/anti-age.png";
import icon_spec from "../images/icons/nurse.png";
import icon_bt from "../images/icons/blood-test.png";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const Service = [
  {
    id: 1,
    ServiceIcon:icon1,
    ServiceName: "Fertility",
    ServicePara:
    "Enclave Healthcare offers comprehensive consultation and evaluation for infertility.We have a dedicated team which understands your anxieties and doubts and is ever willing to patiently discuss the detailed customised treatment plan for you. ",
   
    ServiceImg:fert,

    ServicePara3: "Our Fertility services include ",
    ServicePara4:
      "Fertility Consultation and Counselling",
      ServicePara5: "Follicular Monitoring ",
      ServicePara6:
      "Ovarian stimulation ",
      ServicePara7:
      "Artificial insemination (IUI) ",
      ServicePara8:
      "IVF-ICSI",
      ServicePara9:
      "Sperm Banking ",
      ServicePara10:
      "Surgical Sperm Retrieval",
      ServicePara11:
      "Assisted hatching",
      Slugs:"/obstetrics-gynaecology-clinic",

    },

  {
    id: 2,
    ServiceIcon:icon_fert,
    ServiceName: "IVF",
    ServicePara1:
    "We have a dedicated team of experts who will evaluate your needs and offer the latest Assisted reproductive techniques with state of the Art equipment. ",
    ServicePara2:
      "We strive for continuously upgrading our knowledge and technology to provide you with the latest in the field of infertility treatment  ",
    ServiceImg: IV,

    ServicePara3: "Our  services include ",
    ServicePara4:
      "Fertility Consultation and Counselling",
      ServicePara5: "Follicular Monitoring ",
      ServicePara6:
      "Ovarian stimulation ",
      ServicePara7:
      "IVF-ICSI",
      ServicePara8:
      "IVF-ICSI",
      ServicePara9:
      "Sperm Banking ",
      ServicePara10:
      "Surgical Sperm Retrieval",
      ServicePara11:
      "Assisted hatching",
      Slugs:"/obstetrics-gynaecology-clinic",
  },
 
  {
    id: 3,
    ServiceIcon: icon_pedia,
    ServiceName: "Pediatric",
    ServicePara1:
      "Enclave healthcare provides Comprehensive Pediatric consultations and immunisation services with growth and nutritional evaluation of Children in the  0-18 years of age by Dr Savita Naik a senior Pediatrician with 25 years of experience. ",
    ServicePara2:
      "It has a dedicated Pediatric station for detailed evaluation of the child.",
    ServiceImg: pedia,
    ServicePara3:
    "Enclave healthcare provides Comprehensive Pediatric consultations and immunisation services with growth and nutritional evaluation of Children in the  0-18 years of age by Dr Savita Naik a senior Pediatrician with 25 years of experience. ",
  ServicePara4:
    "It has a dedicated Pediatric station for detailed evaluation of the child.",
    ServicePara5:"Her in-depth knowledge of the subject has helped her successfully treat thousands of patients, giving children the care they deserve. ",
    ServicePara6:"Speciality consultation provided includes Pediatric, Child psychology and Adolescent care.",
  ServicePara7:"Child hospitalisation needs are taken care at ShreeGuru Hospital which is a nearby Pediatric  hospital facility for last 25years",
  Slugs:"/obstetrics-gynaecology-clinic", 
},
  {
    id: 4,
    ServiceIcon:icon_mater,
    ServiceName: "Maternity Services",
    ServicePara1:
    "Enclave Healthcare provides antenatal services from conception till child birth.Even though pregnancy and child birth is a normal physiological process, expecting mothers are very apprehensive about their babies and their own well being during pregnancy! ",
    ServiceImg: mater,

    ServicePara3: "Our Expert Doctor",
    
  
    
    ServicePara4:
      "Dr Sudhir Naik is a highly experienced Obstetrician with over 27 years of experience in handling their concerns and monitoring the progress of their pregnancies. ",
      ServicePara5: "He is highly skilled in normal vaginal deliveries and handling high risk pregnancies",
    
      ServicePara6:
      "At Enclave Healthcare you and your baby will be safely guided through the 9 months of antenatal period by carefully monitoring the growth and proper nutritional advice. ",
      ServicePara7:
      "Dr Sudhir Naik offers delivery services at his own Shree Guru Hospital which is a Center of excellence in the field of Maternity and child care for last 22 years with all equipment for a safe child birth including normal, painless delivery and cesarean section births. ",
      ServicePara8:
      "Dr Savita Naik a highly experienced Neonatologist provides new born care as part of the services which ensures every mother goes back home with a healthy baby. ",
      ServicePara9:
      "Patients requiring tertiary care can be delivered at Cloud 9 and Surya Hospitals where Dr Sudhir Naik is a Visiting Consultant",
      Slugs:"/obstetrics-gynaecology-clinic",
  },
 
  {
    id: 6,
    ServiceIcon:icon_gynaec,
    ServiceName: "Gynaecology",
    ServicePara1:
      "Enclave healthcare offers routine and specialised gynaecological services Routine services include Pap smears for cancer screening, breast examinations, evaluation of pelvic pain, menstrual irregularities and treatment for urinary incontinence. ",
      Slugs:"/obstetrics-gynaecology-clinic",
  },
  {
    id: 7,
    ServiceIcon:icon_spec,
    ServiceName: "Advance Laproscopy",
    ServicePara1:
      "Speciality treatment options like advanced laparoscopic surgeries like hysterectomy, laparoscopic or hysteroscopic removal of fibroids  and endometriosis surgeries are performed at Dr Sudhir Naik’s own Shree Guru and Oyster hospitals which is an advanced laproscopic center.",
      ServicePara2:
      "Diagnostic hysterolaproscopy is done in infertility patients to try and identify uterine anomalies, polyps or blockages in fallopian tubes.",
      ServicePara3:
      "Laproscopy can be used for treatment of various gynaecological problems like fibroids, endometriosis PCOD and for hysterectomies",
      Slugs:"/obstetrics-gynaecology-clinic",
  },

  {
    id: 8,
    ServiceIcon:icon_bt,
    ServiceName: "Diagnostic",
    ServicePara1:
      "Enclave healthcare offers diagnostic services as one of its Speciality services. Infertility blood test panels done as well as  Semen analysis and testing also done ",
      ServiceImg: dermat,
      Slugs:"/obstetrics-gynaecology-clinic",
  },
];

export default Service;
